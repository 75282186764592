<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card
          no-body
          class="mb-0"
        >
          <div>
            <b-row>
              <b-col
                cols="8"
                class="b-col-custom"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                >
                  <feather-icon
                    icon="MonitorIcon"
                  />
                </b-button>
              </b-col>
              <b-col
                cols="4"
                class="b-col-custom"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <flat-pickr
                    v-model="day"
                    class="form-control"
                    :config="configs"
                  />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="d-flex"
                    @click="currentTime()"
                  >
                    <feather-icon
                      icon="ClockIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="8">
        <list-call
          :store-module-name="STORE_MODULE_NAME"
          :day="day"
        />
      </b-col>
      <b-col cols="4">
        <list-driver
          :store-module-name="STORE_MODULE_NAME"
          :day="day"
        />
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BRow, BCol,
  BCard,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BTable, BPagination, BDropdown, BOverlay,
  BDropdownItem,
  BFormSelect,
  BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '@/store/services/hero/callMonitor'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import { Thai } from 'flatpickr/dist/l10n/th.js'
import ListCall from './components/ListCall.vue'
import ListDriver from './components/ListDriver.vue'

const STORE_MODULE_NAME = 'callMonitor'

export default {
  components: {
    flatPickr,
    ListCall,
    ListDriver,
    BRow,
    BCol,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BContainer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      day: this.formatDate(new Date(Date.now())),
      configs: {
        enableTime: false,
        dateFormat: 'd-m-Y',
        locale: Thai,
      },
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
  },
  destroyed() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-')
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
