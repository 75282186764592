<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-tabs
        content-class="mt-2"
        justified
      >
        <b-tab
          :active="type === 'not_assigned' ? true : false"
          @click="clickType('not_assigned')"
        >
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>{{ $t('Not assigned') }}</span>
          </template>
        </b-tab>
        <b-tab
          :active="type === 'assigned' ? true : false"
          @click="clickType('assigned')"
        >
          <template #title>
            <feather-icon icon="ToolIcon" />
            <span>{{ $t('Assigned') }}</span>
          </template>
        </b-tab>
        <b-tab
          :active="type === 'success' ? true : false"
          @click="clickType('success')"
        >
          <template #title>
            <feather-icon icon="EyeOffIcon" />
            <span>{{ $t('Success') }}</span>
          </template>
        </b-tab>
        <b-tab
          :active="type === 'cancel' ? true : false"
          @click="clickType('cancel')"
        >
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>{{ $t('Cancel') }}</span>
          </template>
        </b-tab>
      </b-tabs>
    </div>

    <div class="ml-2 mr-2 mb-2">
      <b-row>
        <b-col>
          <label>{{ $t('Show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector v-select-custom"
            @input="pageSize"
          />
          <label for="">{{ $t('entries') }}</label>
          <span class="ml-2 text-muted">{{ currentPage * perPage - (perPage - 1) }} -
            {{
              queriedItems - currentPage * perPage > 0
                ? currentPage * perPage
                : queriedItems
            }}
            {{ $t("of") }} {{ queriedItems }}</span>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refUserListTable"
      striped
      responsive
      class="position-relative"
      :items="respData"
      :fields="tableColumns"
      primary-key="_id"
      show-empty
      :empty-text="$t('No matching records found')"
      style="height: 60vh;"
    >
      <template #cell(userInfoId)="data">
        <p
          class="align-text-bottom line-height-1"
        >{{ data.item.userInfoId.firstName }} {{ data.item.userInfoId.lastName }}</p>
      </template>
      <template #cell(driver)="data">
        <p
          v-if="data.item.profilePartnerId !== null"
          class="align-text-bottom line-height-1"
        >{{ data.item.profilePartnerId.firstName }} {{ data.item.profilePartnerId.lastName }}</p>
      </template>
      <!-- Column: Action -->
      <template #cell(action)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="openSelectOptionModal(data.item._id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
            {{
              queriedItems - currentPage * perPage > 0
                ? currentPage * perPage
                : queriedItems
            }}
            {{ $t("of") }} {{ queriedItems }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="queriedItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="nextPage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
  BTabs, BTab,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BTable, BPagination, BDropdown, BOverlay,
  BDropdownItem,
  BFormSelect,
  BContainer,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import debounce from 'debounce'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BContainer,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      type: 'not_assigned',
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 30,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'userInfoId', label: this.$t('Member'), sortable: false },
        { key: 'driver', label: this.$t('Driver'), sortable: false },
        { key: 'statusCall', label: this.$t('Status Call'), sortable: false },
        { key: 'priceSum', label: this.$t('Price'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[this.storeModuleName].respData != null ? store.state[this.storeModuleName].respData.data : []
    },
    queriedItems() {
      return store.state[this.storeModuleName].respData != null ? store.state[this.storeModuleName].respData.max : 0
    },
  },
  watch: {
    day(newData, oldData) {
      if (newData) {
        const array = newData.split('-')
        console.log(array)
        // eslint-disable-next-line radix
        console.log(this.formatDate(new Date(parseInt(array[2]), parseInt(array[1]) - 1, parseInt(array[0]))), oldData)
        // eslint-disable-next-line no-unused-vars
        const obj = {
          currentPage: this.currentPage,
          pageSize: this.perPage,
          searchQuery: this.searchQuery,
          // eslint-disable-next-line radix
          day: this.formatDate(new Date(parseInt(array[2]), parseInt(array[1]) - 1, parseInt(array[0]))),
          type: this.type,
        }
        this.show = true
        store
          .dispatch(`${this.storeModuleName}/get`, obj)
        // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.show = false
            // console.log('fetch Success : ', result)
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
  },
  created() {
    // Register module
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
      day: this.day,
      type: this.type,
    }

    this.show = true
    store
      .dispatch(`${this.storeModuleName}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(this.storeModuleName)) store.unregisterModule(this.storeModuleName)
  },
  methods: {
    clickType(data) {
      this.type = data
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        day: this.day,
        type: this.type,
      }
      this.show = true
      store
        .dispatch(`${this.storeModuleName}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-')
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        day: this.day,
        type: this.type,
      }
      this.show = true
      store
        .dispatch(`${this.storeModuleName}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        day: this.day,
        type: this.type,
      }
      this.show = true
      store
        .dispatch(`${this.storeModuleName}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
